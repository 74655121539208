<template>
  <div class="settings-content-wrapper">
    <breadcrumbs :breadcrumbs="breadcrumbs"></breadcrumbs>
    <h1>Service Categories</h1>
    <p>Add categories for the services your business offers.</p>
    <hr>
    <div v-if="serviceCategories">
      <div class="box bunya-box card" v-for="(serviceCategory, index) in serviceCategories" v-bind:key="index">
        <router-link :to="{ name: 'settings-service-categories-show', params: { id: serviceCategory.id }}">
          <div>
            <div class="level is-mobile component-level-margin">
              <div class="level-left">
                <div class="level-item">
                  <h4 class="text">{{ serviceCategory.name }}</h4>
                </div>
              </div>
              <div class="level-right">
                <div class="level-item">
                  <div class="tag is-light">{{ serviceCategory.serviceCount }} services</div>
                </div>
              </div>
            </div>
            <div class="columns">
              <div class="column is-two-thirds">
                <h6 class="subtext">{{ serviceCategory.description }}</h6>
              </div>
            </div>
          </div>
        </router-link>
      </div>
      <hr>
    </div>
    <div v-else-if="isLoaded">
      <b-message type="is-danger">
        <strong>No service categories have been added.</strong>
      </b-message>
      <hr>
    </div>
    <div class="level">
      <div class="level-left"></div>
      <div class="level-right">
        <div class="level-item">
          <router-link :to="{ name: 'settings-service-categories-create' }" class="button is-primary is-fullwidth-mobile">Add Service Category</router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">

  @import '../../scss/_theme-default.scss';

  .card a {
    color: $dark;
  }

  .card a:hover {
    color: lighten($dark, 20);
  }

  .card {
    background-color: #F9F9F9;
  }

  .subtext {
    color: lighten($dark, 40);
  }

</style>

<script>

import Breadcrumbs from '@/components/Breadcrumbs'

export default {
  name: 'ServiceCategories',
  components: { Breadcrumbs },
  data () {
    return {
      breadcrumbs: [
        { name: 'Settings', route: 'settings-home' },
        { name: 'Service Categories', route: null }
      ],
      isLoaded: false
    }
  },
  computed: {
    serviceCategories () {
      return this.$store.getters['settings/serviceCategories']
    }
  },
  mounted () {
    this.$store.dispatch('settings/fetchServiceCategories')
      .then(() => {
        this.isLoaded = true
      })
  }

}
</script>
